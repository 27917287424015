export var config = {
  firebaseConfig: {
    apiKey: "AIzaSyAyy_xJv2BPCGpxNA4tjyJ-ZULBxtoZlvY",
    authDomain: "spotyourdeal-38ab2.firebaseapp.com",
    projectId: "spotyourdeal-38ab2",
    storageBucket: "spotyourdeal-38ab2.appspot.com",
    messagingSenderId: "716076846166",
    appId: "1:716076846166:web:8a5ca23e4c403ab8413ff7"
  }
};
