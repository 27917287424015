import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoadingController } from '@ionic/angular';
import firebase from 'firebase/compat/app';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {HttpErrorResponse } from '@angular/common/http';
import {throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class StoreService {
pDetail: any;
 url1: any='https://spotyourdeal.com'
url: any='https://us-central1-spotyourdeal-38ab2.cloudfunctions.net/api'
// url: any='https://8066-103-49-235-141.ngrok.io'
//url: any='http://192.168.1.37:3000'

  constructor(public http :HttpClient) { }
  preDifnedCat(): Observable<any> {
    return this.http.get(this.url+'/fetchPredefineCatagories')
  }
  topBannerImg(): Observable<any> {
    return this.http.get(this.url+'/fetchBannerPromoMobile',)
  }
  middleBannerImg(): Observable<any> {
    return this.http.get(this.url+'/fetchBannerPromoMobileMiddle')
  }
  middleBannerImg1(DisObj): Observable<any> {
    return this.http.post(this.url+'/fetchBannerPromoMobileMiddle1',DisObj)
  }
  // fetchPromoByOffers(postData: any): Observable<any> {
  //   return this.http.post(this.url+'/findPromotionsByPriceFilterMobile', postData)
  // }
  webProductCat(postData: any): Observable<any> {
    return this.http.post(this.url+'/mobileProductCatImg', postData)
  }
  sendGioData(postData: any): Observable<any> {
    return this.http.post(this.url+'/sendGeo', postData)
  }
  dfetchAllShipAddress(postData: any): Observable<any> {
    // return this.httpService.post('/dummaySmileMobilePayment',postData)
    return this.http.post(this.url+'/fetchallshipaddress', postData)
  }
  recommendedProducts(postData: any): Observable<any> {
    return this.http.post(this.url+'/fetchrecommendations',postData)
  }

  // storeCategory(postData: any): Observable<any> {
  //   return this.http.post(this.url+'/subcategoryproducts', postData)
  // }

  addToCart(postData: any): Observable<any> {
    return this.http.post(this.url+'/addToCartMobile', postData)
  }

  fetchProductDetail(postData: any): Observable<any> {
    return this.http.post(this.url+'/fetchProductDetail', postData)
  }

  addToWishlist(postData: any): Observable<any> {
    return this.http.post(this.url+'/wishlist', postData)
  }

  removeToWishlist(postData: any): Observable<any> {
    return this.http.post(this.url+'/removeWishlist', postData)
  }

  recommendedProducts1(postData: any): Observable<any> {
    return this.http.post(this.url+'/fetchProductrecommendations', postData)
  }

  getProductrating(postData: any): Observable<any> {
    return this.http.post(this.url+'/producRatingCount', postData)
  }

  getReview(postData: any): Observable<any> {
    return this.http.post(this.url+'/getReview', postData)
  }

  async orderHistoryAPI(postData: any){
    console.log("orderHistoryAPI ===================")
    const token = await firebase.auth().currentUser.getIdToken(true);

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    });
    
    let options = { headers: headers };
    return new Promise(async (resolve, reject) => {
    console.log("orderHistoryAPI options =================== ",options)
    this.http.post(this.url+'/userOrderHistory', postData, options).subscribe(resp => {
      console.log("orderHistoryAPI resp =================== ",resp)
      resolve(resp);
    }, error => {
      console.log("orderHistoryAPI error =================== ",error)
      reject(error);
    })
    })
  }

  cancelOrder(postData: any): Observable<any> {
    return this.http.post(this.url+'/CustomercancelOrder', postData)
  }

  ProductReview(postData: any): Observable<any> {
    return this.http.post('/review', postData)
  }

  lastDelivered(postData: any): Observable<any> {
    return this.http.post(this.url+'/lastDelivered', postData)
  }

  ProductRating(postData: any): Observable<any> {
    return this.http.post(this.url+'/ratingProduct', postData)
  }

  returnOrder(postData: any): Observable<any> {
    return this.http.post(this.url+'/Returnrequest', postData)
  }

  colllectCoins(postData : any): Observable<any>{
    return this.http.post(this.url+'/distributeSpotCoinsMLM',postData);
  }

  todaySepicalOffer(postData: any): Observable<any> {
    return this.http.post(this.url+'/todayOffersDetail', postData)
  }

  storeProductSearch(postData: any): Observable<any> {
    return this.http.post(this.url+'/fetchProductNameMobile', postData)
  }

  getGiftCard(): Observable<any> {
    return this.http.get(this.url+'/getgiftCard')
  }

  dummaySmileMobilePayment(postData: any): Observable<any> {
    // return this.httpService.post('/dummaySmileMobilePayment',postData)
    return this.http.post(this.url+'/paymentInvoiceCheck', postData)
  }

  razorPayment(postData: any): Observable<any> {
    return this.http.post(this.url+'/rozarVarifyPaymentMobile', postData)
  }

  deleteAdd(postData : any): Observable<any>{
    return this.http.post(this.url+'/deleteAddressMobile',postData)
  }


  addShippingAddr(postData: any): Observable<any> {
    // return this.httpService.post('/dummaySmileMobilePayment',postData)
    return this.http.post(this.url+'/updateShipaddress', postData)
  }

  storeList(data): Observable <any>{
    return this.http.post(this.url+'/FetchallstoreMobile',data);
  }

  productList(data): Observable <any>{
    return this.http.post(this.url+'/fetchAllProductMobile',data);
  }

  cunsumerTier(postData: any): Observable<any> {
    return this.http.post(this.url+'/customerTierDecide', postData)
  }

  reward(postData: any): Observable<any> {
    return this.http.post(this.url+'/fetchrewardpoints', postData)
  }

  redeem(postData: any): Observable<any> {
    return this.http.post(this.url+'/redeemRewardPoints', postData)
  }

  redeemGift(postData: any): Observable<any> {
    return this.http.post(this.url+'/redeemgiftCard', postData)
  }

  raiseIssue(postData: any): Observable<any> {
    return this.http.post(this.url+'/feedbackMobile', postData)
  }

  

  getNearbyStore(postData: any): Observable<any> {
    return this.http.post(this.url+'/GetNearbyStore', postData)
  }

  getReferralCode(postData: any): Observable<any> {
    return this.http.post(this.url+'/fetchReferralCode', postData)
  }

  fetchWishlist(postData: any): Observable<any> {
    return this.http.post(this.url+'/fetchWishlistProduct', postData)
  }

  moveTocart(postData: any): Observable<any> {
    return this.http.post(this.url+'/moveToCart', postData)
  }

  getcart(postData: any): Observable<any>{
    return this.http.post(this.url+'/getCartProducts',postData)
  }

 wishlist(postData):Observable<any>{
   return this.http.post(this.url+'/newWishlist',postData)
 } 

  deleteCart(postData: any): Observable<any> {
    return this.http.post(this.url+'/removeToCart', postData)
  }

  cartDelete(postData:any):Observable<any>{
    return this.http.post(this.url+'/deleteToCartMobile',postData)
  }

  refferalOffers(postData: any): Observable<any> {
    return this.http.post(this.url+'/referralCounter', postData)
  }

  login(postData: any): Observable<any> {
   
    return this.http.post(this.url1+'/login/mobile/new', postData)
    
  }

  signup(postData: any): Observable<any> {
    return this.http.post(this.url+'/NewregisterforMobile', postData)
  }

  matchOtp(postData: any): Observable<any> {
    return this.http.post(this.url+'/matchOtp', postData)
  }

  forgotPassword(postData: any): Observable<any> {
    return this.http.post(this.url+'/forgotPassword', postData)
  }

  changePassoerd(postData: any): Observable<any> {
    return this.http.post(this.url+'/changePwdByOtp', postData)
  }

  getProduct(postData : any): Observable<any>{
    return this.http.post(this.url+'/getProducts',postData)
  }

  deliveryFee(postData : any): Observable<any>{
    return this.http.post(this.url+'/deliveryFees',postData)
  }
  hasVariantDetail(postData : any): Observable<any>{
    return this.http.post(this.url+'/fetchVariants',postData)
  }

    async checkout(postData: any){
    const token = await firebase.auth().currentUser.getIdToken(true);

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    });
    
    let options = { headers: headers };

    return new Promise(async (resolve, reject) => {
      this.http.post(this.url+'/createOrder', postData, options).subscribe(resp => {
        console.log("checkout resp =================== ",resp)
        resolve(resp);
      }, error => {
        console.log("checkout error =================== ",error)
        reject(error);
      })
      })

      
  }
  addMoneyRazorPay(postData: any): Observable<any> {
    return this.http.post(this.url + '/addMoney', postData)
  }
  customerSupport(postData: any): Observable<any> {
    return this.http.post(this.url + '/customerSupport', postData)
  }
 // Edit By Vikash Kumar 
      // All Category Api
      categoryList(data:any): Observable<any> {
        return this.http.post(this.url+'/getcategories',data)
      }
      // Top Deals Api
      trendingProductA(data:any): Observable<any> {
        return this.http.post(this.url+'/trendingProduct',data)
      }
    //  Location Search Api 
      addressLookup(search:any): Observable<any> {
        console.log("Response map", search)
        return this.http.get(`https://nominatim.openstreetmap.org/search/`+search.value+`?format=json&addressdetails=1&limit=10`)
        
      }
      // by default location api
      getIpAddress() {
        return this.http
              .get('https://api.ipify.org/?format=json')
              .pipe(
                catchError(this.handleError)
              );
      } 
    
       getGEOLocation(ip) {
    
      let url = "https://api.ipgeolocation.io/ipgeo?apiKey=AIzaSyDsSo1aQWDo5qSnPlRDYkKHV04fU2swgCI&ip="+ip+"&fields=geo"; 
        return this.http
              .get(url)
              .pipe(
                catchError(this.handleError)
              );
      } 
    
      private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
    
          console.error('An error occurred:', error.error.message);
        } else {
    
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        
        return throwError(
          'Something bad happened; please try again later.');
      }
      // edit by vikash
      addToWishlist1(postData: any): Observable<any> {
        return this.http.post(this.url+'/addWishlist', postData)
      }
      deleteWish(postData: any): Observable<any> {
        return this.http.post(this.url+'/deleteWishlist', postData)
      }
      bigSaving(data:any): Observable<any> {
        return this.http.post(this.url+'/bigsaving',data)
      }
      NewAddProduct(data:any): Observable<any> {
        return this.http.post(this.url + '/getnewproducts', data)
      }
}
