import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [

  {
    path: 'register',
    loadChildren: () => import('./auth/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./auth/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'store-list',
    loadChildren: () => import('./pages/store-list/store-list.module').then( m => m.StoreListPageModule)
  },
  {
    path: 'product-list',
    loadChildren: () => import('./pages/product-list/product-list.module').then( m => m.ProductListPageModule)
  },
  {
    path: 'wishlist',
    loadChildren: () => import('./pages/wishlist/wishlist.module').then( m => m.WishlistPageModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/orders/orders.module').then( m => m.OrdersPageModule)
  },
  {
    path: 'grocery-store/:category',
    loadChildren: () => import('./pages/sub-category-list/sub-category-list.module').then( m => m.SubCategoryListPageModule)
  },
  {
    path: 'product-detail',
    loadChildren: () => import('./pages/product-detail/product-detail.module').then( m => m.ProductDetailPageModule)
  },
  // {
  //   path: 'add-to-cart',
  //   loadChildren: () => import('./pages/add-to-cart/add-to-cart.module').then( m => m.AddToCartPageModule)
  // },
  {
    path: 'order-history',
    loadChildren: () => import('./pages/order-history/order-history.module').then( m => m.OrderHistoryPageModule)
  },
  {
    path: 'order-history-detail',
    loadChildren: () => import('./pages/order-history-detail/order-history-detail.module').then( m => m.OrderHistoryDetailPageModule)
  },
  {
    path: 'spot-wallet',
    loadChildren: () => import('./pages/spot-wallet/spot-wallet.module').then( m => m.SpotWalletPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./auth/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./auth/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'store-list/:cat/:subCat',
    loadChildren: () => import('./pages/store-list/store-list.module').then( m => m.StoreListPageModule)
  },
  {
    path: 'product-list/:category/:subCat',
    loadChildren: () => import('./pages/product-list/product-list.module').then( m => m.ProductListPageModule)
  },
  {
    path: 'wishlist',
    loadChildren: () => import('./pages/wishlist/wishlist.module').then( m => m.WishlistPageModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/orders/orders.module').then( m => m.OrdersPageModule)
  },
  {
    path: 'sub-category-list',
    loadChildren: () => import('./pages/sub-category-list/sub-category-list.module').then( m => m.SubCategoryListPageModule)
  },
  {
    path: 'product-detail/:id',
    loadChildren: () => import('./pages/product-detail/product-detail.module').then( m => m.ProductDetailPageModule)
  },
  // {
  //   path: 'add-to-cart',
  //   loadChildren: () => import('./pages/add-to-cart/add-to-cart.module').then( m => m.AddToCartPageModule)
  // },
  {
    path: 'order-history',
    loadChildren: () => import('./pages/order-history/order-history.module').then( m => m.OrderHistoryPageModule)
  },
  {
    path: 'order-history-detail',
    loadChildren: () => import('./pages/order-history-detail/order-history-detail.module').then( m => m.OrderHistoryDetailPageModule)
  },
  {
    path: 'request-cancel',
    loadChildren: () => import('./pages/request-cancel/request-cancel.module').then( m => m.RequestCancelPageModule)
  },
  {
    path: 'review-page',
    loadChildren: () => import('./pages/review-page/review-page.module').then( m => m.ReviewPagePageModule)
  },
  {
    path: 'raise-issue',
    loadChildren: () => import('./pages/raise-issue/raise-issue.module').then( m => m.RaiseIssuePageModule)
  },
  {
    path: 'today-offer',
    loadChildren: () => import('./pages/today-offer/today-offer.module').then( m => m.TodayOfferPageModule)
  },
  {
    path: 'offers',
    loadChildren: () => import('./pages/offers/offers.module').then( m => m.OffersPageModule)
  },
  {
    path: 'search-product',
    loadChildren: () => import('./pages/search-product/search-product.module').then( m => m.SearchProductPageModule)
  },
  {
    path: 'spot-gift-card',
    loadChildren: () => import('./pages/spot-gift-card/spot-gift-card.module').then( m => m.SpotGiftCardPageModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/checkout/checkout.module').then( m => m.CheckoutPageModule)
  },
  {
    path: 'order-confirm',
    loadChildren: () => import('./pages/order-confirm/order-confirm.module').then( m => m.OrderConfirmPageModule)
  },{
    path : 'invite',
    loadChildren : () => import('./pages/invite/invite.module').then(m=>m.InvitePageModule)
  },
  {
    path: 'address',
    loadChildren: () => import('./pages/address/address.module').then( m => m.AddressPageModule)
  },
  {
    path: 'grocery-list/:category/:subCat',
    loadChildren: () => import('./pages/grocery-list/grocery-list.module').then( m => m.GroceryListPageModule)
  },
  {
    path: 'loginn',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'forgot-passwordd',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./loginmobile/loginmobile.module').then( m => m.LoginmobilePageModule)
  },
  {
    path: 'registration',
    loadChildren: () => import('./pages/registration/registration.module').then( m => m.RegistrationPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: '',
    loadChildren: () => import('./pages/website-home/website-home.module').then( m => m.WebsiteHomePageModule)
  },
  {
    path: 'cart',
    loadChildren: () => import('./pages/cart/cart.module').then( m => m.CartPageModule)
  },
  {
    path: 'nearby',
    loadChildren: () => import('./pages/nearby/nearby.module').then( m => m.NearbyPageModule)
  },
  {
    path: 'wholeseller',
    loadChildren: () => import('./pages/register-aswholeseller/register-aswholeseller.module').then( m => m.RegisterAswholesellerPageModule)
  },
  {
    path: 'privacy-page',
    loadChildren: () => import('./privacy-page/privacy-page.module').then( m => m.PrivacyPagePageModule)
  },
  {
    path: 'account-page',
    loadChildren: () => import('./pages/account/account.module').then( m => m.AccountPageModule)
  },
  {
    path: 'termsandconditions',
    loadChildren: () => import('./termsandconditions/termsandconditions.module').then( m => m.TermsandconditionsPageModule)
  },
  {
    path: 'shippingpolicy',
    loadChildren: () => import('./shippingpolicy/shippingpolicy.module').then( m => m.ShippingpolicyPageModule)
  },
  {
    path: 'refundandcancellation',
    loadChildren: () => import('./refundandcancellation/refundandcancellation.module').then( m => m.RefundandcancellationPageModule)
  },
  {
    path: 'air-conditioner',
    loadChildren: () => import('./newproduct/air-conditioner/air-conditioner.module').then( m => m.AirConditionerPageModule)
  },
  {
    path: 'appliances',
    loadChildren: () => import('./newproduct/appliances/appliances.module').then( m => m.AppliancesPageModule)
  },
  {
    path: 'baby-care',
    loadChildren: () => import('./newproduct/baby-care/baby-care.module').then( m => m.BabyCarePageModule)
  },
  {
    path: 'bag',
    loadChildren: () => import('./newproduct/bag/bag.module').then( m => m.BagPageModule)
  },
  {
    path: 'beauty',
    loadChildren: () => import('./newproduct/beauty/beauty.module').then( m => m.BeautyPageModule)
  },
  {
    path: 'brand',
    loadChildren: () => import('./newproduct/brand/brand.module').then( m => m.BrandPageModule)
  },
  {
    path: 'coming',
    loadChildren: () => import('./newproduct/coming/coming.module').then( m => m.ComingPageModule)
  },
  {
    path: 'electronic-accessories',
    loadChildren: () => import('./newproduct/electronic-accessories/electronic-accessories.module').then( m => m.ElectronicAccessoriesPageModule)
  },
  {
    path: 'mixer',
    loadChildren: () => import('./newproduct/mixer/mixer.module').then( m => m.MixerPageModule)
  },
  {
    path: 'tv',
    loadChildren: () => import('./newproduct/refrigerator/refrigerator.module').then( m => m.RefrigeratorPageModule)
  },
  {
    path: 'watch',
    loadChildren: () => import('./newproduct/watch/watch.module').then( m => m.WatchPageModule)
  },
  {
    path: 'women-watch',
    loadChildren: () => import('./newproduct/women-watch/women-watch.module').then( m => m.WomenWatchPageModule)
  },
  {
    path: 'womenfootwear',
    loadChildren: () => import('./newproduct/womenfootwear/womenfootwear.module').then( m => m.WomenfootwearPageModule)
  },
  {
    path: 'speakers',
    loadChildren: () => import('./newproduct/speakers/speakers.module').then( m => m.SpeakersPageModule)
  },
  {
    path: 'mobile-accessories',
    loadChildren: () => import('./newproduct/accessories/accessories.module').then( m => m.AccessoriesPageModule)
  },
  {
    path: 'addwish',
    loadChildren: () => import('./addwish/addwish.module').then( m => m.AddwishPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'light',
    loadChildren: () => import('./newproduct/light/light.module').then( m => m.LightPageModule)
  },
  {
    path: 'trendingproduct',
    loadChildren: () => import('./newproduct/trendingproduct/trendingproduct.module').then( m => m.TrendingproductPageModule)
  },
  {
    path: 'alloffers',
    loadChildren: () => import('./newproduct/alloffers/alloffers.module').then( m => m.AlloffersPageModule)
  },
  {
    path: 'bigsaving',
    loadChildren: () => import('./newproduct/bigsaving/bigsaving.module').then( m => m.BigsavingPageModule)
  },
  {
    path: 'top-deals',
    loadChildren: () => import('./newproduct/top-deals/top-deals.module').then( m => m.TopDealsPageModule)
  },
  {
    path: 'new-product',
    loadChildren: () => import('./newproduct/new-product/new-product.module').then( m => m.NewProductPageModule)
  },
  {
    path: 'fans',
    loadChildren: () => import('./newproduct/fans/fans.module').then( m => m.FansPageModule)
  },
  {
    path: 'pet-food',
    loadChildren: () => import('./newproduct/pet-food/pet-food.module').then( m => m.PetFoodPageModule)
  },
  {
    path: 'hair',
    loadChildren: () => import('./newproduct/hair/hair.module').then( m => m.HairPageModule)
  },
  {
    path: 'facewash',
    loadChildren: () => import('./newproduct/facewash/facewash.module').then( m => m.FacewashPageModule)
  },
  {
    path: 'maggi',
    loadChildren: () => import('./newproduct/maggi/maggi.module').then( m => m.MaggiPageModule)
  },
  {
    path: 'namkeen',
    loadChildren: () => import('./newproduct/namkeen/namkeen.module').then( m => m.NamkeenPageModule)
  },

  // { path: '404', component: NotFoundComponent },
  // { path: '**', redirectTo: '/404' }
 

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
