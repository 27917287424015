import { Component, OnInit,Input } from '@angular/core';
import { StoreService } from '../services/store.service';
import { ToastService } from '../services/toast.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'product-listt',
  templateUrl: './product-listt.component.html',
  styleUrls: ['./product-listt.component.scss'],
})
export class ProductListtComponent implements OnInit {
   //Edit by Sharaddha
   cart: any = [];
   qty: number = 1;
   show: any;
  @Input() item;

  userId = localStorage.getItem("userId");
  loading : boolean=false;
  a:boolean=false;

  constructor( private userService: UserService,private authService : StoreService, private toast : ToastService, private router : Router) { }

  ngOnInit() {}
  handleAddToCart(productObj) {
    let promoListobj = {
      productdetails: productObj,
      userId: this.userId
    }
   
    this.authService.addToCart(promoListobj)
      .subscribe((result: any) => {
        this.toast.presentToast("Product added in your cart")
      }, (error: any) => {
      });
  }
  
  productDetail(productItem: any) {
    console.log("hello hi ", productItem)
    this.router.navigate(['./product-detail/'+productItem._id]);
  }

  // onImageLoad(item){
  //   console.log("hii")
  //   item.image=true;
  //   this.loading=true;
  //   if(item.image==true){
  //     this.a='visible'
  //   }
  //   else{
  //     this.a='none'
  //   }
  // }

  handleMissingImage($event){
    console.log($event)
    console.log("hello error");
    ($event.target as HTMLImageElement).style.display = 'none'
    this.a=true;
  }
  handleAddToCarta(productObj) {
    console.log("productObj ", productObj)

    this.cart = []
    if (productObj.qty) {///Added by Parul
      ++productObj.qty
    }
    else {
      productObj['qty'] = 1;
    }
    let cart = localStorage.getItem("cart");
    if (cart) {
      this.cart = JSON.parse(cart);
      console.log("cart ", this.cart)
      let filtered = this.cart.filter(product => product.id == productObj._id)
      console.log("filtered ", filtered)
      if (filtered.length == 0) {
        var pdata = {
          id: productObj._id,
          qty: this.qty
        }
        console.log(pdata)
        this.cart.push(pdata)
        localStorage.setItem('cart', JSON.stringify(this.cart))
        // this.show = 1
      } else {
        console.log(this.show)
        let index = this.cart.indexOf(filtered[0]);
        console.log("index ", index)
        this.cart[index]['qty'] = productObj.qty;//Added by Parul
        // this.cart.splice(index, 1)
        localStorage.setItem('cart', JSON.stringify(this.cart))
        // this.show = null

      }
    } else {
      console.log("item._id ", productObj._id)
      var pdata = {
        id: productObj._id,
        qty: this.qty
      }
      this.cart.push(pdata)
      localStorage.setItem('cart', JSON.stringify(this.cart))
      this.show = 1
    }
    this.userService.cartUpdated.next(true);
  }

  deleteFromCart(item) {
    if (item.qty > 0) {
      --item.qty;
      this.cart = []
      let cart = localStorage.getItem("cart");
      if (cart) {
        this.cart = JSON.parse(cart);
        console.log("cart ", this.cart)
        let filtered = this.cart.filter(product => product.id == item._id)
        let index = this.cart.indexOf(filtered[0])
        this.cart[index]['qty'] = item.qty;
        if (item.qty == 0) {
          this.cart.splice(index, 1)
        }
        localStorage.setItem('cart', JSON.stringify(this.cart))
        this.userService.cartUpdated.next(true);
      }
      // this.loadCartItems();

    }
  }
  isRetailer(){
    if(localStorage.getItem('retailerEnabled')=="1") 
     return true;
   }

}


