import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductListtComponent } from './product-listt.component';






@NgModule({
  declarations: [ProductListtComponent],
  imports: [
    CommonModule,
    //ProductListtComponent
  ],
  exports:
  [
    ProductListtComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ProductListtModule { }
