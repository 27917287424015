import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';


@Injectable()
export class LoadingService {

  loading: any;
  loadingPresent: boolean = false;

  constructor(public toastCtrl: ToastController,
    public loadingCtrl: LoadingController) {

    //  console.log('Hello LoadingProvider Provider');

  }

  // For present loading
  // presentLoading() {
  //  this.loading = this.loadingCtrl.create({
  //    content: 'Please wait...'
  //  });
  //  this.loading.present();
  // }

  async presentLoading(message) {
    this.loading = await this.loadingCtrl.create({
      message: message,
      translucent: true,
      spinner: 'crescent',
      duration: 5000
    });
    this.loadingPresent = true;
    await this.loading.present();

    // const { role, data } = await this.loading.onDidDismiss();

    // console.log('Loading dismissed!');
  }
  
  async presentLoadingInfinite(message) {
    this.loading = await this.loadingCtrl.create({
      message: message,
      translucent: true,
      spinner: 'crescent'
    });
    this.loadingPresent = true;
    await this.loading.present();

    // const { role, data } = await this.loading.onDidDismiss();

    // console.log('Loading dismissed!');
  }


  dismiss() {
    this.loadingPresent = true;
    this.loading.dismiss();
  }

  // Toast message
  // presentToast(text) {
  //  let toast = this.toastCtrl.create({
  //    message: text,
  //    duration: 3000,
  //    position: 'top'
  //  });
  //  toast.present();
  // }

}
